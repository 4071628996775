<template>
  <div class="wrap-date-range-picker">
    <div class="date-range-picker" :class="checkBorderDatePicker()">
      <!-- Date from -->
      <div class="flex50">
        <v-menu
          v-model="menuDateFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="270px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFromValue"
              :placeholder="$t('place_holders.date-from')"
              append-icon="mdi-calendar"
              dense
              v-bind="attrs"
              hide-details
              flat
              solo
              class="rounded-lg"
              :clearable="!isRequired"
              v-on="on"
              @click:clear="onClearDateFrom"
              @click:append="menuDateFrom = !menuDateFrom"
              @input="onChangeDateFrom"
              @focus="onFocusDateFrom"
              @blur="blurDateFrom"
            />
          </template>
          <v-date-picker
            v-model="dateFromPicker"
            format="DD/MM/YYYY"
            :locale="localDate"
            no-title
            @input="menuDateFrom = false"
            @change="pickerDateFromChange"
          />
        </v-menu>
      </div>
      <div class="vertical-divider" />

      <!-- Date to -->
      <div class="flex50">
        <v-menu
          v-model="menuDateTo"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="270px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateToValue"
              :placeholder="$t('place_holders.date-to')"
              append-icon="mdi-calendar"
              dense
              v-bind="attrs"
              hide-details
              flat
              solo
              :clearable="!isRequired"
              class="rounded-lg"
              v-on="on"
              @click:clear="onClearDateTo"
              @click:append="menuDateTo = !menuDateTo"
              @input="onChangeDateTo"
              @focus="onFocusDateTo"
              @blur="blurDateTo"
            />
          </template>
          <v-date-picker
            v-model="dateToPicker"
            format="DD/MM/YYYY"
            :locale="localDate"
            no-title
            @input="menuDateTo = false"
            @change="pickerDateToChange"
          />
        </v-menu>
      </div>
    </div>

    <!-- Error date -->
    <span v-if="errorDate" class="text-date-error">
      {{ $t(errorDate) }}
    </span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DateRangePicker",

  props: {
    isRequired: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // Date from.
      menuDateFrom: null,
      dateFromPicker: null,
      dateFromValue: null,
      errorDateFrom: null,
      focusDateFrom: false,

      // Date to.
      menuDateTo: null,
      dateToPicker: null,
      dateToValue: null,
      errorDateTo: null,
      focusDateTo: false,

      errorDate: null,
    };
  },

  computed: {
    localDate() {
      return this.$i18n.locale === "vi" ? "vi-VN" : "en-US";
    },
  },

  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    pickerDateFromChange(date) {
      this.dateFromValue = this.formatDate(date);
      this.$emit("setDateFrom", this.dateFromValue);
      this.onChangeDateFrom();
    },

    pickerDateToChange(date) {
      this.dateToValue = this.formatDate(date);
      this.$emit("setDateTo", this.dateToValue);
      this.onChangeDateTo();
    },

    onClearDateFrom() {
      this.dateFromPicker = null;
      this.dateFromValue = null;
      this.errorDate = null;
      this.errorDateFrom = null;
      this.$emit("setDateFrom", this.dateFromValue);
      this.onChangeDateTo();
      if (this.errorDate) return;
      this.$emit("searchOrder");
    },

    onClearDateTo() {
      this.dateToPicker = null;
      this.dateToValue = null;
      this.errorDate = null;
      this.errorDateTo = null;
      this.$emit("setDateTo", this.dateToValue);
      this.onChangeDateFrom();
      if (this.errorDate) return;
      this.$emit("searchOrder");
    },

    onChangeDateFrom() {
      if (this.dateFromValue) {
        this.errorDate = null;
        this.errorDateFrom = null;
        var valid = moment(this.dateFromValue, "DD/MM/YYYY", true).isValid();

        if (!valid) {
          this.dateFromPicker = null;
          this.errorDateFrom = "dateFromInvalid";
        }

        if (!valid && !this.dateToValue) {
          this.errorDate = "dateFromInvalid";
          return;
        }

        if (!valid && this.errorDateTo === "dateToInvalid") {
          this.errorDate = "dateFromAndToInvalid";
          return;
        }

        if (!valid) {
          this.errorDate = "dateFromInvalid";
          return;
        }

        if (this.errorDateTo) {
          this.errorDate = this.errorDateTo;
          return;
        }

        // Case valid.
        // If dateFrom valid but dateTo inValied.
        if (this.errorDateTo === "dateToInvalid") {
          this.$emit("setDateFrom", this.dateFromValue);
          return;
        }

        if (this.isRequired && !this.dateToValue) {
          this.errorDate = "dateToRequired";
          this.errorDateTo = "dateToRequired";
          return;
        }

        if (this.dateToValue) {
          this.dateFromPicker = null;
          const dateTo = moment(this.dateToValue, "DD/MM/YYYY");
          const dateFrom = moment(this.dateFromValue, "DD/MM/YYYY");
          const diff = dateTo.diff(dateFrom, "days");
          this.dateFromPicker = moment(this.dateFromValue, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );

          if (diff < 0) {
            this.errorDate = "messError.compareDate";
            return;
          }

          if (diff > 365) {
            this.errorDate = "messError.dateExpire";
            return;
          }
        }

        this.$emit("setDateFrom", this.dateFromValue);
        this.$emit("searchOrder");
        return;
      }

      // case dateFromValue = null.
      if (this.isRequired && !this.dateToValue) {
        this.errorDate = "messError.dateRequired";
        return;
      }

      if (this.isRequired) {
        this.errorDate = "dateFromRequired";
        this.errorDateFrom = "dateFromRequired";
      }
    },

    onChangeDateTo() {
      if (this.dateToValue) {
        this.errorDate = null;
        this.errorDateTo = null;
        var valid = moment(this.dateToValue, "DD/MM/YYYY", true).isValid();

        if (!valid) {
          this.dateToPicker = null;
          this.errorDateTo = "dateToInvalid";
        }

        if (!valid && !this.dateFromValue) {
          this.errorDate = "dateToInvalid";
          return;
        }

        if (!valid && this.errorDateFrom === "dateFromInvalid") {
          this.errorDate = "dateFromAndToInvalid";
          return;
        }

        if (!valid) {
          this.errorDate = "dateToInvalid";
          return;
        }

        if (this.errorDateFrom) {
          this.errorDate = this.errorDateFrom;
          return;
        }

        // Case valid.
        // If dateFrom valid but dateTo inValied.
        if (this.errorDateFrom === "dateFromInvalid") {
          this.$emit("setDateTo", this.dateToValue);
          return;
        }

        if (this.isRequired && !this.dateFromValue) {
          this.errorDate = "dateFromRequired";
          this.errorDateFrom = "dateFromRequired";
          return;
        }

        if (this.dateFromValue) {
          this.dateToPicker = null;
          const dateTo = moment(this.dateToValue, "DD/MM/YYYY");
          const dateFrom = moment(this.dateFromValue, "DD/MM/YYYY");
          const diff = dateTo.diff(dateFrom, "days");
          this.dateToPicker = moment(this.dateToValue, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );

          if (diff < 0) {
            this.errorDate = "messError.compareDate";
            return;
          }

          if (diff > 365) {
            this.errorDate = "messError.dateExpire";
            return;
          }
        }

        this.$emit("setDateTo", this.dateToValue);
        this.$emit("searchOrder");
        return;
      }

      // case dateToValue = null.
      if (this.isRequired && !this.dateFromValue) {
        this.errorDate = "messError.dateRequired";
        return;
      }

      if (this.isRequired) {
        this.errorDate = "dateToRequired";
        this.errorDateTo = "dateToRequired";
      }
    },

    setDateCreate(toDate) {
      if (!this.dateToValue) {
        this.dateToValue = toDate
        this.dateToPicker = moment(this.dateToValue, "DD/MM/YYYY").format("YYYY-MM-DD")
      }
    },

    resetData() {
      // Date from.
      this.menuDateFrom = null;
      this.dateFromPicker = null;
      this.dateFromValue = null;
      this.errorDateFrom = null;

      // Date to.
      this.menuDateTo = null;
      this.dateToPicker = null;
      this.dateToValue = null;
      this.errorDateTo = null;

      this.errorDate = null;
      this.$emit("setDateFrom", this.dateFromValue);
      this.$emit("setDateTo", this.dateToValue);
    },

    initDate() {
      this.dateFromValue = moment().subtract(365, "days").format("DD/MM/YYYY");
      this.dateToValue = moment().format("DD/MM/YYYY");

      this.dateFromPicker = moment(this.dateFromValue, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      this.dateToPicker = moment(this.dateToValue, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );

      this.errorDateFrom = null;
      this.errorDateTo = null;
      this.errorDate = null;

      this.$emit("setDateFrom", this.dateFromValue);
      this.$emit("setDateTo", this.dateToValue);
      this.$emit("searchOrder");
    },

    checkBorderDatePicker() {
      if (this.errorDate) return "date-range-picker-error";
      if (this.menuDateFrom || this.menuDateTo) {
        return "date-range-picker-focus";
      }
      if (this.focusDateFrom || this.focusDateTo) {
        return "date-range-picker-focus";
      }
      return "none";
    },

    onFocusDateFrom() {
      this.focusDateFrom = true;
    },

    blurDateFrom() {
      this.focusDateFrom = false;
    },

    onFocusDateTo() {
      this.focusDateTo = true;
    },

    blurDateTo() {
      this.focusDateTo = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-date-range-picker {
  display: flex;
  flex-direction: column;
  .date-range-picker {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    align-items: center;
    &:hover {
      border: 1px solid black;
    }

    .flex50 {
      flex: 50;
    }

    .vertical-divider {
      height: 20px;
      width: 1px;
      background-color: rgba(15, 18, 63, 0.44);
    }
  }

  .date-range-picker-error {
    border: 1px solid #ff5252 !important;
  }

  .date-range-picker-focus {
    border: 2px solid #0fafe4 !important;
  }

  .text-date-error {
    font-size: 12px;
    color: #ff5252;
    margin-top: 3px;
    margin-left: 12px;
    font-weight: 600;
  }
}
</style>
